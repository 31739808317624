import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import { ListItemSpacing, ListVariant } from '@aurora/shared-client/components/common/List/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import type {
  Article,
  BlogTopicMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ContentWorkflowState,
  CoverImageStyle,
  CoverImageTitlePosition,
  RepliesFormat
} from '@aurora/shared-generated/types/graphql-schema-types';
import dynamic from 'next/dynamic';
import React, { useContext, useRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { contextMessageToMessageView } from '../../../helpers/messages/MessageHelper/MessageHelper';
import type { ItemType } from '../../../types/enums';
import {
  MessageActionMenuItem,
  MessageViewStandardAvatarPosition,
  MessageViewVariant
} from '../../../types/enums';
import type {
  ContextMessageFragment,
  MessageParentFragment,
  MessageRepliesCountFragment
} from '../../../types/graphql-types';
import type { WidgetFC } from '../../common/Widget/types';
import { UrlObject, useContextObjectRefFromUrl } from '../../context/useContextObjectFromUrl';
import type { ItemViewTypeAndProps } from '../../entities/types';
import useRepliesSettingsProperties from '../../messages/LinearReplyList/useRepliesSettingsProperties';
import MessageDeepLink from '../../messages/MessageDeepLink/MessageDeepLink';
import MessageReplyCallToAction from '../../messages/MessageReplyCallToAction/MessageReplyCallToAction';
import MessageView from '../../messages/MessageView/MessageView';
import { useAutoScrollToReplies } from '../../messages/useAutoScrollToReplies';
import type { MessageWithRepliesWidgetProps } from '../../messages/types';
import { useIsPreviewMode } from '../../messages/useCurrentOrPreviewMessage';
import useInView from '../../useInView';
import localStyles from './BlogArticleWidget.module.pcss';

const ThreadedReplyList = dynamic(
  () => import('../../messages/ThreadedReplyList/ThreadedReplyList')
);

const LinearReplyList = dynamic(() => import('../../messages/LinearReplyList/LinearReplyList'));

/**
 *
 * @constructor
 *
 * @author Sravan Reddy
 */
const BlogArticleWidget: WidgetFC<MessageWithRepliesWidgetProps> = props => {
  const {
    className,
    listVariant = {
      type: ListVariant.UNSTYLED,
      props: {
        listItemSpacing: ListItemSpacing.SM
      }
    },
    viewVariant = {
      type: MessageViewVariant.STANDARD,
      props: {
        avatarPosition: MessageViewStandardAvatarPosition.TOP,
        useReplies: true,
        useRepliesCount: true,
        useNodeLink: false,
        useKudosCount: true,
        useAcceptedSolutionButton: false,
        useTags: true,
        useFooterDivider: true,
        useSolvedBadge: false,
        useIntroduction: true,
        useAttachments: true,
        adminActionMenuItems: [
          { item: MessageActionMenuItem.EDIT_INLINE },
          { item: MessageActionMenuItem.MOVE_COMMENT },
          { item: MessageActionMenuItem.COPY_LINK },
          { item: MessageActionMenuItem.SUBSCRIBE },
          { item: MessageActionMenuItem.DELETE }
        ],
        actionMenuItems: [
          { item: MessageActionMenuItem.REPORT_ABUSE },
          { item: MessageActionMenuItem.MARK_AS_APPROVED },
          { item: MessageActionMenuItem.REJECT },
          { item: MessageActionMenuItem.MARK_AS_NOT_SPAM },
          { item: MessageActionMenuItem.BAN_MEMBER },
          { item: MessageActionMenuItem.EDIT_BAN_MEMBER }
        ],
        className: 'lia-g-message-box',
        useAccordionForReply: true,
        flattenDepth: 7,
        useReadOnlyIcon: true,
        useCustomFields: true
      }
    },
    pagerVariant,
    pageSize = 10,
    lazyFetchReplies = true
  } = props;
  const cx = useClassNameMapper(localStyles);
  const isPreviewMode = useIsPreviewMode();
  const [inViewRef, inView] = useInView<HTMLDivElement>();
  const { id: commentId } = useContextObjectRefFromUrl(UrlObject.REPLY);
  const { isCrawler, contextMessage } = useContext(AppContext);
  const showDeepLinkedComment = commentId !== null;
  const editorRef = useRef(null);

  const contentWorkflow = (contextMessage as Article)?.contentWorkflow;
  const coverImage = (contextMessage as BlogTopicMessage)?.coverImage;
  const coverImageProperties = (contextMessage as BlogTopicMessage)?.coverImageProperties;
  const isMessagePreviewAndDraft =
    isPreviewMode && contentWorkflow?.state !== ContentWorkflowState.Publish;
  const autoScrollToReplies = useAutoScrollToReplies();

  const { repliesFormat, repliesFormatLoading } = useRepliesSettingsProperties(
    contextMessage?.board?.id
  );
  const isThreadedRepliesFormat = repliesFormat === RepliesFormat.Threaded;

  if (repliesFormatLoading) {
    return null;
  }

  const titlePosition = coverImage
    ? coverImageProperties?.titlePosition
    : CoverImageTitlePosition.Bottom;
  const coverImageStyle = coverImage ? coverImageProperties?.style : CoverImageStyle.Standard;
  const wideOverlayTitleBottom =
    coverImageStyle === CoverImageStyle.Wide &&
    (titlePosition === CoverImageTitlePosition.Overlay ||
      titlePosition === CoverImageTitlePosition.Bottom);

  const topicViewVariant: ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant> = {
    type: viewVariant.type,
    props: {
      ...viewVariant.props,
      useSubject: false,
      useCoverImageTitle: true,
      useCoverImage: true,
      useTimeToRead: true,
      useNodeTitle: true,
      useSubjectLink: false,
      subjectAs: 'h1',
      className: cx(`${viewVariant?.props?.className ?? ''} lia-article`),
      contentClassName: cx('lia-blog-content', {
        'lia-blog-content-overlay':
          titlePosition === CoverImageTitlePosition.Overlay &&
          coverImageStyle === CoverImageStyle.Wide
      }),
      avatarSize: IconSize.PX_50,
      actionMenuItems: null,
      useRevision: true,
      useAccordionForReply: false,
      useReplies: false,
      useRepliesCount: false,
      useTextBody: true,
      usePreview: showDeepLinkedComment,
      usePreviewMedia: true,
      clampBodyLines: showDeepLinkedComment ? 2 : null
    }
  };
  const decoratedViewVariant = { ...viewVariant };
  decoratedViewVariant.props.className = cx('lia-g-message-box');
  decoratedViewVariant.props.useSubject = false;
  decoratedViewVariant.props.useIntroduction = false;
  decoratedViewVariant.props.useRepliesCount =
    decoratedViewVariant.props.useRepliesCount && isThreadedRepliesFormat;
  decoratedViewVariant.props.useAccordionForReply =
    decoratedViewVariant.props.useAccordionForReply && isThreadedRepliesFormat;
  decoratedViewVariant.props.repliesFormat = repliesFormat;

  return (
    <div className={cx(className, { 'lia-wide-overlay-title-bottom': wideOverlayTitleBottom })}>
      <div className={cx('lia-message-body-container')} ref={editorRef}>
        <MessageView
          entity={contextMessageToMessageView(contextMessage)}
          variant={topicViewVariant}
        />
      </div>
      <div ref={inViewRef}>
        {showDeepLinkedComment ? (
          <>
            <MessageDeepLink rootMessage={contextMessage} viewVariant={viewVariant} />
            {<MessageReplyCallToAction message={contextMessage} />}
          </>
        ) : (
          !isMessagePreviewAndDraft && (
            <>
              {!isCrawler &&
              !autoScrollToReplies &&
              lazyFetchReplies &&
              !inView ? null : repliesFormat === RepliesFormat.Threaded ? (
                <ThreadedReplyList
                  message={
                    contextMessage as ContextMessageFragment &
                      MessageRepliesCountFragment &
                      MessageParentFragment
                  }
                  listVariant={listVariant}
                  viewVariant={decoratedViewVariant}
                  pagerVariant={pagerVariant}
                  pageSize={pageSize}
                  useEmpty
                  editorRef={editorRef}
                />
              ) : (
                <LinearReplyList
                  message={
                    contextMessage as ContextMessageFragment &
                      MessageRepliesCountFragment &
                      MessageParentFragment
                  }
                  viewVariant={decoratedViewVariant}
                  listVariant={listVariant}
                  pagerVariant={pagerVariant}
                  useEmpty
                  editorRef={editorRef}
                />
              )}
              {
                <MessageReplyCallToAction
                  message={contextMessage}
                  className={cx({
                    'lia-reply-cta-wrapper':
                      contextMessage?.repliesCount > 0 &&
                      viewVariant.props.useAccordionForReply &&
                      isThreadedRepliesFormat
                  })}
                />
              }
            </>
          )
        )}
      </div>
    </div>
  );
};

export default BlogArticleWidget;
